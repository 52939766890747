<script setup lang="ts">
import type { LineContact } from '~/types/LineContact';
import AlertDialogLoading from '~/components/ui/alert-dialog/AlertDialogLoading.vue';
import { Skeleton } from '~/components/ui/skeleton';

// PROPS
interface Props {
    lineContacts: LineContact[];
    loading?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
    loading: false,
});
const { lineContacts, loading } = toRefs(props);

// LAZY LOAD COMPONENT
const LineContactViewer = defineAsyncComponent(() => import('./LineContactViewer.vue'));

// MODAL STATE
const lineContactViewerState = ref<boolean>(false);
const selectedLineContact = ref<LineContact>();

// MODEL
const page = defineModel<number>('page', { required: true });

// EMIT
type Emit = {
    refresh: [];
};
const emit = defineEmits<Emit>();

// FUNCTION
function prevPage() {
    if (page.value === 1) return;
    page.value--;
    emit('refresh');
}

function nextPage() {
    page.value++;
    emit('refresh');
}

function openLineContactViewer(lineContact: LineContact) {
    selectedLineContact.value = lineContact;
    lineContactViewerState.value = true;
}
</script>

<template>
    <div class="overflow-hidden rounded-xl bg-white dark:bg-stone-700 border border-stone-300 dark:border-stone-600">
        <table class="table-fixed w-full">
            <thead class="bg-stone-200 dark:bg-stone-800 text-stone-700 dark:text-stone-400 text-left">
                <tr class="border-b border-stone-200 dark:border-stone-600">
                    <th class="w-[40%] p-3 px-5 font-medium">ชื่อผู้ติดต่อ</th>
                    <th class="w-[30%] p-3 px-5 font-medium">Engage ล่าสุด</th>
                    <th class="w-[30%] p-3 px-5 font-medium">แท็ก</th>
                </tr>
            </thead>
            <tbody class="text-stone-700 dark:text-stone-200">
                <template v-if="loading">
                    <tr v-for="i in 10" :key="i" class="border-b border-stone-200 dark:border-stone-600 cursor-pointer hover:bg-stone-100 dark:hover:bg-stone-800 transition">
                        <td class="py-3 px-5">
                            <Skeleton class="w-full h-4" />
                        </td>
                        <td class="py-3 px-5">
                            <Skeleton class="w-full h-4" />
                        </td>
                        <td class="py-3 px-5">
                            <Skeleton class="w-full h-4" />
                        </td>
                    </tr>
                </template>
                <tr
                    v-for="lineContact in lineContacts"
                    v-else
                    :key="lineContact.id"
                    class="border-b border-stone-300 dark:border-stone-600 cursor-pointer hover:bg-stone-100 dark:hover:bg-stone-800 transition"
                    @dblclick="openLineContactViewer(lineContact)"
                >
                    <td class="p-2 px-5">
                        <div class="flex items-center gap-2">
                            <Avatar class="w-8 h-8">
                                <AvatarImage :src="lineContact.profileUrl || ''" />
                                <AvatarFallback>{{ lineContact.name.charAt(0) }}</AvatarFallback>
                            </Avatar>
                            <p>{{ lineContact.name }}</p>
                        </div>
                    </td>
                    <td class="p-2 px-5">{{ formatDateAndTime(lineContact.lastEngagedAt) }}</td>
                    <td class="p-2 px-5">
                        <div class="flex gap-3">
                            <div
                                v-for="tag in lineContact.tags"
                                :key="tag.id"
                                class="rounded-full px-2 py-1 text-sm text-center w-fit bg-stone-200 dark:bg-stone-800 text-stone-700 dark:text-stone-400"
                            >
                                {{ tag.name }}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="!loading" class="bg-stone-200 dark:bg-stone-800 text-stone-700 dark:text-stone-400 text-left">
                <tr>
                    <td colspan="2" class="p-3 px-5">
                        <p>ข้อมูลทั้งหมด {{ lineContacts.length }} แถว</p>
                    </td>
                    <td class="p-2 px-5">
                        <PaginateBar :page="page" @next-page="nextPage" @prev-page="prevPage" />
                    </td>
                </tr>
            </tfoot>
        </table>

        <Suspense v-if="lineContactViewerState && selectedLineContact">
            <LineContactViewer v-model:open="lineContactViewerState" :line-contact-id="selectedLineContact.id" />
            <template #fallback>
                <AlertDialogLoading />
            </template>
        </Suspense>
    </div>
</template>
