import type { LineContact } from '~/types/LineContact';
import type { GetAllApiMetadata, GetAllApiMetadataWithPaginate, GetApiMetadata, GetApiOptions, Paginate } from '~/types/Global';

const defaultGetApiMetadata: GetAllApiMetadata = { search: '', filter: {}, include: [], orderBy: {} };

const defaultGetApiOptions: GetApiOptions = {};

const BASE_URL = '/v1/line-contacts';

export function useLineContact() {
    async function getAll(metadata: GetAllApiMetadata = defaultGetApiMetadata, options: GetApiOptions = defaultGetApiOptions): Promise<LineContact[]> {
        try {
            const { data, error } = await useApiFetch<LineContact[]>(`${BASE_URL}/get`, {
                method: 'POST',
                body: metadata,
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return [];
        }
    }

    async function getAllWithPaginate(metadata: GetAllApiMetadataWithPaginate, options: GetApiOptions = defaultGetApiOptions): Promise<Paginate<LineContact[]>> {
        try {
            const { data, error } = await useApiFetch<Paginate<LineContact[]>>(`${BASE_URL}/get`, {
                method: 'POST',
                body: metadata,
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return { prevPage: 0, nextPage: 0, data: [], currentPage: 0 };
        }
    }

    async function getById(id: string, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<LineContact | null> {
        try {
            const { data, error } = await useApiFetch<LineContact>(`${BASE_URL}/${id}`, {
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function update(
        id: string,
        payload: Partial<LineContact> | { tags?: string[] },
        metadata: GetApiMetadata = defaultGetApiMetadata,
        options: Pick<GetApiOptions, 'errorCallback'> = {},
    ): Promise<LineContact | null> {
        try {
            const { data, error } = await useApiFetch<LineContact>(`${BASE_URL}/${id}`, {
                method: 'PATCH',
                body: payload,
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    return {
        getAll,
        getAllWithPaginate,
        getById,
        update,
    };
}
