<script setup lang="ts">
import type { LineContact } from '~/types/LineContact';
import LineContactTable from '~/components/sales/deeplink/contact/table.vue';
import AlertDialogLoading from '~/components/ui/alert-dialog/AlertDialogLoading.vue';

// META
useHead({
    title: 'Line Contact',
});

// SERVICE
const { $toast } = useNuxtApp();
const lineContactService = useLineContact();

// VARIABLE
const lineContacts = ref<LineContact[]>([]);
const page = ref<number>(1);
const addCampaignModalState = ref<boolean>(false);
const search = ref<string>('');
const isLoading = ref<boolean>(false);

async function getLineContacts() {
    const data = await lineContactService.getAllWithPaginate(
        { search: search.value, limit: 10, page: page.value, include: ['tags'] },
        { errorCallback: () => $toast.error('เกิดข้อผิดพลาดระหว่างดึงข้อมูลผู้ติดต่อในไลน์') },
    );

    return data.data;
}

async function refreshLineContacts() {
    isLoading.value = true;
    lineContacts.value = await getLineContacts();
    isLoading.value = false;
}

const debouncedRefreshLineContacts = useDebounceFn(refreshLineContacts, 250);

// ON MOUNTED
onMounted(async () => {
    isLoading.value = true;
    lineContacts.value = await getLineContacts();
    isLoading.value = false;
});
</script>

<template>
    <NuxtLayout name="deeplink">
        <div class="flex justify-between items-center gap-3 mb-5">
            <h1 class="text-4xl font-medium dark:text-white">ผู้ติดต่อ</h1>
        </div>

        <SearchBar v-model="search" class="mb-5" placeholder="ค้นหาผู้ติดต่อด้วยชื่อไลน์ / Line ID" @search="debouncedRefreshLineContacts" />
        <LineContactTable v-model:page="page" :line-contacts="lineContacts" :loading="isLoading" @refresh="refreshLineContacts" />

        <Suspense v-if="addCampaignModalState">
            <AddCampaignModal v-model:open="addCampaignModalState" />
            <template #fallback>
                <AlertDialogLoading />
            </template>
        </Suspense>
    </NuxtLayout>
</template>
